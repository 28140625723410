import { Injectable } from '@angular/core';

@Injectable()
export class LanguagesService {
  constructor() {}

  public getLanguagesValue(value: any, iso: string = 'ru-RU') {
    switch (window.navigator.language) {
      case 'en': case 'en-US':
        iso = 'en-US';
      break;
      case 'be': case 'be-BY':
        iso = 'be-BY';
        break;
      case 'kk': case 'kk-KZ':
        iso = 'kk-KZ';
        break;
      case 'ka': case 'ka-GE':
        iso = 'ka-GE';
        break;
      default:
        iso = 'ru-RU';
        break;
    }
    try {
      if (typeof value === 'object') {
        if (value.extra[iso])
          return value.extra[iso];
        return value.extra['ru-RU'];
      }
      const _ = JSON.parse(value);
      if (_.hasOwnProperty(iso)) {
        if (_[iso]) { return _[iso]; }
        if (_['ru-RU']) { return _['ru-RU']; }
        return value;
      }
    } catch {
      return value;
    }
  }
}
